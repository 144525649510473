/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import { graphql } from 'gatsby'
import Layout from 'gatsby-theme-tailwindui/src/components/layout'
import React, { useState } from 'react'
import { useSiteMetadata } from '../../gatsby-theme-tailwindui/utils/use-site-metadata'

const Email = (props) => {
  const email = props.data.sanityEmails
  const { globalContactMenu, globalNavMenu } = useSiteMetadata()

  const menuItems = globalNavMenu
  const contactMenu = globalContactMenu
  return (
    <Layout navMenuItems={menuItems} contactMenuItems={contactMenu}>
      <div>
        <h1>{email.title}</h1>
        <div>
          <p>{email.description}</p>
        </div>
      </div>
    </Layout>
  )
}

export default Email

export const query = graphql`
  query ($id: String!) {
    sanityEmails(id: { eq: $id }) {
      id
      title
      description
      url
    }
  }
`
